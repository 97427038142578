<template>
  <div v-show="loading" class="about">
    <img
      v-if="image"
      class="about-image"
      src="../assets/mobius2.png"
      alt="mobius image"
      @click="image = !image"
      @load="loading = true"
    />
    <iframe
      v-else
      src="https://player.vimeo.com/video/743533740?h=566cc8fa04?autoplay=1&loop1&muted=1&background=1&title=0&byline=0&portrait=0&sidedock=0"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
      class="about-video"
    >
    </iframe>
    <div class="about-text">
      <div class="texts">
        <div class="who">
          <p
            class="
              animate__animated
              animate__pulse
              animate__delay-2s
              animate__slow
              animate__infinite
            "
          >
            who am i ?
          </p>
          <p
            class="
              animate__animated
              animate__pulse
              animate__delay-3s
              animate__slow
              animate__infinite
              who-right
            "
          >
            @mobiusvisio
          </p>
        </div>
        <p
          class="
            animate__animated
            animate__pulse
            animate__delay-1s
            animate__slow
            animate__infinite
            rookie
          "
        >
          <span>ROOKIE</span> VIDEO MAKER
        </p>
        <p
          class="
            animate__animated
            animate__pulse
            animate__delay-2s
            animate__slow
            animate__infinite
            whatido
          "
        >
          what i do:
        </p>
        <p
          class="
            animate__animated
            animate__pulse
            animate__delay-6s
            animate__slow
            animate__infinite
            video
          "
        >
          VIDEO+PHOTO
        </p>
        <div class="based">
          <p
            class="
              animate__animated
              animate__pulse
              animate__delay-2s
              animate__slow
              animate__infinite
            "
          >
            based in
          </p>
          <p
            class="
              animate__animated
              animate__pulse
              animate__delay-4s
              animate__slow
              animate__infinite
              based-right
            "
          >
            PARIS
          </p>
        </div>
        <div class="coming">
          <p
            class="
              animate__animated
              animate__pulse
              animate__delay-3s
              animate__slow
              animate__infinite
            "
          >
            coming from
          </p>
          <p
            class="
              animate__animated
              animate__pulse
              animate__delay-2s
              animate__slow
              animate__infinite
              coming-right
            "
          >
            LILLE !
          </p>
        </div>
      </div>
      <div class="about-contact">
        <p>more:</p>
        <div class="contacts">
          <p>mobiusvisio@gmail.com</p>
          <div class="contacts-logo">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/mobiusvisio/"
              ><img src="../assets/instagram.png" alt="insta"
            /></a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://youtube.com/playlist?list=PLYf-BGGizyN8FySvaNON9bQhz3-3h7ox_"
              ><img class="youtube" src="../assets/youtube.png" alt="youtube"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    image: true,
    loading: false,
  }),
  watch: {
    loading(val) {
      if (val) {
        this.$emit("loaded");
      }
    },
  },
};
</script>

<style>
.about {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ebebeb;
}
.about-image {
  max-width: 550px;
  margin-left: -125px;
}
.about-image {
  cursor: url(../assets/pointer.png), auto;
}
.about-video {
  width: 550px;
  height: 550px;
}
.about-text {
  margin-left: 50px;
}
.who {
  display: flex;
  margin-left: 15px;
  transform: rotate(-5deg);
}
.who-right {
  margin-top: 35px;
  margin-left: 35px;
  transform: rotate(8deg);
  color: #94d900;
}
.rookie {
  margin-top: -2px;
  transform: rotate(-2deg);
  text-align: right;
}
.rookie > span {
  text-decoration: line-through;
}
.whatido {
  transform: rotate(4deg);
  margin-left: -5px;
  color: #94d900;
}
.video {
  margin-top: -14px;
  transform: rotate(-4deg);
  text-align: right;
  margin-right: 25px;
}
.based {
  margin-top: -15px;
  display: flex;
  transform: rotate(-3deg);
}
.based-right {
  margin-top: 25px;
  margin-left: 15px;
  transform: rotate(8deg);
  color: #94d900;
}
.coming {
  margin-top: -40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 170px;
  transform: rotate(-1deg);
}
.coming > p,
.about-contact p {
  margin: 0;
}
.contacts-logo img:hover {
  cursor: url(../assets/pointer.png), auto;
}
.coming-right {
  margin-top: 5px !important;
  color: #94d900;
}
.about-contact {
  margin-top: 45px;
  border-bottom: 2px solid #94d900;
  padding-bottom: 10px;
}
.contacts {
  margin-top: 10px;
  display: flex;
}
.contacts-logo {
  display: flex;
  margin-left: 15px;
}
.contacts-logo img {
  max-width: 22px;
  margin-left: 10px;
  filter: invert(1);
}
.contacts-logo .youtube {
  transform: scale(1.25);
  margin-left: 12px;
}

@media (max-width: 992px) {
  .about-image {
    max-width: 440px;
  }
}
@media (max-width: 576px) {
  .about {
    flex-direction: column;
  }
  .about-image {
    max-width: 350px;
    margin-left: -40px;
    margin-top: -50px;
  }
  .about-video {
    width: 350px;
    height: 426px;
  }
  .about-text {
    margin-left: 0;
    padding-bottom: 50px;
  }
}
</style>
